import { Button, CustomButton } from "elements";
import { noop } from "utils";

import "./AgeModal.scss";
import {toggleAgeModal} from "features";
import {useAppDispatch} from "store";
import {useNavigate} from "react-router-dom";

const AgeModalForm = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const handlerAgeClick = (bool: boolean) => {
        if (!bool) {
            navigate("/catalog");
        }
        localStorage.setItem("age", `${bool}`);
        dispatch(toggleAgeModal(false))
    }

  return (
    <div className="body-modal-age">
      <p className="text-modal-age">
        В разделе находятся товары, которые доступны только совершеннолетним пользователям
      </p>

      <div className="button-box-modal-age">
        <div className="box-button-false">
            <CustomButton
                classNames={['outline-btn']}
                classNamesText={'outline-btn__text'}
                label={"Нет, мне нет 18"}
                onClick={() => handlerAgeClick(false)}
            />
        </div>
        <div className="box-button-true">
          <CustomButton
            type="button"
            label="Да, мне есть 18"
            onClick={() => handlerAgeClick(true)}
            // loadingLabel="Сохранение..."
          />
        </div>
      </div>
    </div>
  );
};

export default AgeModalForm;
