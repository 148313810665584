import { Col, Container, Row } from "react-bootstrap";

import {
  CatalogBreadcrumb,
  CatalogGroupsGrid,
  CatalogItemsGrid,
} from "components";
import {useLocation} from "react-router-dom";
import {toggleAgeModal} from "features";
import {useAppDispatch} from "store";
import {useEffect} from "react";

export type CatalogRoute = {
  parentGroup?: string;
  childGroup?: string;
};

const CatalogPage = () => {
  const dispatch = useAppDispatch();
  // useEffect(() => {
  //   dispatch(getFilters({}));
  // }, []);

    const matchStrPathName = useLocation().pathname.match(/catalog\/p5[0-9]/);
    const storedValue = localStorage.getItem("age");
    const isAge = storedValue ? JSON.parse(storedValue) : false;

  useEffect(() => {
    if (matchStrPathName && !isAge) {
      dispatch(toggleAgeModal(true))
    }
  }, [matchStrPathName, isAge]);

  return (
    <Container className="container-page-catalog">
      <CatalogBreadcrumb />
      <Row>
        <Col>
          <CatalogGroupsGrid />
          <CatalogItemsGrid />
        </Col>
      </Row>
    </Container>
  );
};

export default CatalogPage;
