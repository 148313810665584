import { useAppSelector } from "store";

import { ModalWindow } from "components";
import { isAgeModalOpenSelector, toggleAgeModal } from "features";

import AgeModalForm from "./AgeModalForm";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

import AgeSvg from "image/18+.svg";

const AgeModal = () => {
  const navigate = useNavigate();
  const isModalOpen = useAppSelector(isAgeModalOpenSelector);

  useEffect(() => {
    if(!isModalOpen) {
      const storedValue = localStorage.getItem("age");
      const isAge = storedValue ? JSON.parse(storedValue) : false;

      if(!isAge) { navigate("/catalog"); }
    }
  }, [isModalOpen]);

  return (
    <ModalWindow
      classNames={'block-modal-age'}
      isOpen={isModalOpen}
      toggleAction={toggleAgeModal}
      title={<h2 className="exit-form-title-block"> <img src={AgeSvg} alt="18+" className="img-geo" width={24} height={24}/> <span>Вам есть 18 лет?</span></h2>}
    >
      <AgeModalForm />
    </ModalWindow>
  );
};

export default AgeModal;
